<template>
  <div>
    <v-icon color="primary" class="icon-container mt-3">
      mdi-check
    </v-icon>
    <p class="mt-5">
      {{ $t('views.Withdraw.finish.desc', {
        amount: $n(amount, 'floatCurrency')
      }) }}
    </p>
    <v-btn
      color="primary"
      class="black--text"
      @click="$router.push('/profile')"
    >
      {{ $t('views.Withdraw.finish.btn') }}
    </v-btn>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Finish',
  computed: {
    ...mapGetters('withdraw', [
      'amount',
    ]),
  },
  methods: {
    ...mapActions('withdraw', [
      'nextStep',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.icon-container {
  border: 1px solid var(--v-primary-base);
  border-radius: 50%;
  padding: 0.5rem;
}
</style>
